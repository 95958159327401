import React from "react";
import {
  Stack,
  List,
  ListItem,
  Heading,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { Link } from "gatsby";

import Layout from "../components/Layout";

const gepReviewPanel = [
  {
    name: "Brian Oliver",
    position: "GEP Review Panel Lead; Science Officer",
    affiliation:
      "National Institute of Diabetes and Digestive and Kidney Diseases Bethesda, MD USA",
  },
  {
    name: "Thom Kaufman",
    position: "Fly Science Consultant",
    affiliation: "Indiana University, Bloomington, IN USA",
  },
  {
    name: "Lynn Crosby",
    position: "FlyBase Curator Consultant",
    affiliation: "FlyBase.org",
  },
  {
    name: "Terence Murphy",
    position: "NCBI/RefSeq Curator Consultant",
    affiliation: "NCBI",
  },
  {
    name: "Stephen Schaeffer",
    position: "12 Fly Genomes Data Consultant",
    affiliation: "Pennsylvania State University",
  },
  {
    name: "Elizabeth Rideout",
    position: "Domain Expert",
    affiliation: "University of British Columbia, CA",
  },
  {
    name: "Irene Miquel-Aliaga",
    position: "Domain Expert",
    affiliation: "Imperial College London, UK",
  },
  {
    name: "Alex Gould",
    position: "Domain Expert",
    affiliation: "Francis Crick Institute, UK",
  },
];

const Profiles = () => {
  return (
    <Stack align="center" paddingTop="100px">
      <Heading color="gray">Review Panels</Heading>
      <u>
        <Link to="/about/for-authors/#peer-review-panel-for-multiple-submission-projects">
          Peer Review Panel for Multiple Submssion Projects
        </Link>
      </u>
      <Heading color="gray" size="md">
        GEP Insulin Ortholog Review Panel 3/2/22
      </Heading>
      <List>
        {gepReviewPanel.map((person) => (
          <ListItem>
            <Flex
              display="col"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              align="top"
            >
              <Box p="3" align="left" colorScheme="gray">
                <Box fontWeight="bold" lineHeight="tight">
                  {person.name}
                </Box>
                <Box>
                  <Text>
                    {person.position} <em>{person.species}</em>
                  </Text>
                  <Text fontSize="sm">{person.affiliation}</Text>
                </Box>
              </Box>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

const EditorialStaff = (props) => {
  return <Layout children={<Profiles />} />;
};

export default EditorialStaff;
